//== Button Small
@mixin button-small {
  font-family: $nunito-medium;
  font-size: 0.625rem;
  line-height: 1rem;
  padding: 0.69rem 1rem;
  text-transform: uppercase;
  letter-spacing: 0.075rem;
  transition: 300ms ease all;
  border-radius: 1px;
  &:focus,
  &:visited,
  &:active {
    outline: 0;
  }

  &:hover {
    transition: 300ms ease all;
    background-color: $neutral-white;
    color: $neutral-black;
    outline: 0;
  }
}

.button-small {
  @include button-small;
}

//== Button Regular
@mixin button-regular {
  font-family: $nunito-medium;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1.25rem;
  text-transform: uppercase;
  letter-spacing: 0.075rem;
  transition: 300ms ease all;
  border-radius: 1px;
  &:focus,
  &:visited,
  &:active {
    outline: 0;
  }

  &:hover {
    transition: 300ms ease all;
    background-color: $neutral-white;
    color: $neutral-black;
    outline: 0;
  }
}
.button-regular {
  @include button-regular;
}

//== Button Dashboard
@mixin button-dashboard {
  font-family: $nunito-medium;
  font-size: 0.625rem;
  line-height: 1rem;
  padding: 0.45rem 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: 300ms ease all;
  border-radius: 1px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus,
  &:visited,
  &:active {
    outline: 0;
  }

  &:hover {
    transition: 300ms ease all;
    background-color: $neutral-white;
    color: $neutral-black;
    outline: 0;
    border-radius: 50%;
    height: auto;
    width: auto;
  }
}

.button-dashboard {
  @include button-dashboard;
  cursor: pointer;
}

//== Button Primary
@mixin button-primary {
  font-family: $nunito-medium;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1rem;
  background-color: $neutral-black;
  padding: 0.7rem 1.25rem;
  text-transform: uppercase;
  color: $neutral-white;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.075rem;
  transition: 300ms ease all;
  border: 1px solid $neutral-black;
  border-radius: 1px;
  &:focus,
  &:visited,
  &:active {
    outline: 0;
  }

  &:hover {
    transition: 300ms ease all;
    background-color: $neutral-white;
    border: 1px solid $neutral-black;
    color: $neutral-black;
    outline: 0;
  }
}

.button-primary {
  @include button-primary;
}

//== Button Primary V2
@mixin button-primary-v2 {
  font-family: $nunito-medium;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1rem;
  background-color: $neutral-black;
  padding: 0.75rem 1.25rem;
  text-transform: uppercase;
  color: $neutral-white;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.075rem;
  transition: 300ms ease all;
  border: 0.063rem solid transparent;
  border-radius: 1px;
  &:focus,
  &:visited,
  &:active {
    outline: 0;
  }

  &:hover {
    transition: 300ms ease all;
    background-color: $neutral-white;
    border: 0.063rem solid $neutral-black;
    color: $neutral-black;
    outline: 0;
  }
}

.button-primary-v2 {
  @include button-primary-v2;
}

// Button Secondary
@mixin button-secondary {
  font-family: $nunito-medium;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1rem;
  background-color: $neutral-white;
  padding: 0.75rem 1.25rem;
  text-transform: uppercase;
  color: $neutral-black;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.075rem;
  transition: 300ms ease all;
  border-radius: 1px;
  &:focus,
  &:visited,
  &:active {
    outline: 0;
  }

  &:hover {
    transition: 300ms ease all;
    background-color: $neutral-black;
    color: $neutral-white;
    outline: 0;
  }
}

.button-secondary {
  @include button-secondary;
}

//== Button Transparent
$button-transparent-types: 'black' $neutral-black $neutral-white,
  'white' $neutral-white $neutral-black, 'gray' $gray $neutral-white;

@each $name, $color, $inverse-color in $button-transparent-types {
  .button-transparent-#{$name} {
    font-family: $nunito-medium;
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 1rem;
    background-color: transparent;
    padding: 0.7rem 1.25rem;
    text-transform: uppercase;
    border: 0.063rem solid #{$color};
    color: #{$color};
    text-align: center;
    cursor: pointer;
    letter-spacing: 0.075rem;
    transition: 300ms ease all;
    border-radius: 1px;

    &:focus,
    &:visited,
    &:active {
      outline: 0;
    }

    &:hover {
      transition: 300ms ease all;
      background-color: #{$color};
      border: 0.063rem solid #{$color};
      color: #{$inverse-color};
      outline: 0;
    }
  }
}

//== Button Badge
@mixin button-badge {
  font-family: $nunito-medium;
  font-size: 0.625rem;
  line-height: 1.2;
  color: $gray-dark;
  letter-spacing: 0.1em;
  border-radius: 0.125rem;
  width: max-content;
  text-transform: uppercase;
  padding: 4px 6px;

  &:focus,
  &:visited,
  &:active {
    outline: 0;
  }
}

.button-badge {
  @include button-badge;
}

//== Button Select
@mixin button-select {
  font-family: $nunito-medium;
  font-size: 0.625rem;
  line-height: 1rem;
  padding: 0;
  text-transform: uppercase;
  letter-spacing: 0.075rem;
  transition: 300ms ease all;
  border-radius: 1px;
  letter-spacing: 1px;
  color: $gray-dark;
  display: flex;
  align-items: center;

  &:focus,
  &:visited,
  &:active {
    outline: 0;
  }

  &:hover {
    transition: 300ms ease all;
    background-color: $neutral-white;
    color: $neutral-black;
    outline: 0;
  }

  img {
    margin-left: 0.5rem;
  }
}

.button-select {
  @include button-select;
}

//== Util
.button-smaller {
  @include button-small;
  padding: 0.6rem 0.8rem;
  padding-right: 0.8rem;
}

.button-smallest {
  @include button-small;
  padding: 0.45rem 0.6rem;
}

.btn-icon {
  display: flex;
  align-items: center;
  img {
    max-width: 12px;
    margin-right: 0.3rem;
  }
}

.btn-upload-img {
  max-width: 12px;
  margin-right: 0.5rem;
}

.btn-large {
  padding-left: 2.1rem;
  padding-right: 2.1rem;
}

.btn-fs-large {
  font-size: 12px;
  letter-spacing: 0.1em;
}

.button-wide {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.button-wider {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}
