@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.annotation-3d-modal {
  & .modal--regular .modal__content {
    width: 101%;
    height: 95%;
    top: 49%;
  }

  & .modal__inner {
    max-height: 100%;
    height: 85%;
    display: flex;
  }

  .annotation-comment-section {
    border-left: 1px solid $login-bg-color;
    width: 288px;
    position: relative;
    span {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: "NunitoSans-Bold", sans-serif;
      letter-spacing: 0.1em;
      font-size: 0.625rem;
      font-size: 0.75rem;
      line-height: 130%;
      text-transform: uppercase;
    }
    .annotation-empty {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      color: $login-bg-color;
      text-align: center;

      div {
        display:flex;
        justify-content: center;
        align-items: center;
      }

    }
    .annotation-comment-wrapper {
      position: absolute;
      top: 0;
      background-color: #fff;
      width: 100%;
      height: 100%;
      z-index: 100;
    }
    .annotation-comment-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      padding: 24px 16px;
      border-bottom: 1px solid $login-bg-color;
      color: $black-2;

      &__items-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .annotation-comment-textbox {
      & .collection-access-modal__label {
        & .control-block {
          margin-bottom: 0.4rem;

          & .control--textarea {
            height: 3rem;
            resize: none;
          }
          
        }
        
        & .annotation-comment-form-wrapper {
          & .button-small {
            padding: 0.3rem 0.5rem;
            margin-right: 15px;
            font-size: 0.6rem;
          }
        }
      }
    }

    .annotation-comment-name {
      display: flex;
      align-items: center;
      padding: 24px 16px 12px;
      & .profile {
        width: 1.5rem;
        height: 1.5rem;
      }
      span {
        margin-left: 5px;
        font-family: "Nunito", sans-serif;
        font-style: normal;
        font-weight: 100;
        font-size: 10px;
        line-height: 130%;
        letter-spacing: normal;
        text-transform: capitalize;
      }
      
      & .notifications__time {
        margin-left: 5px;
      }
    }

    .annotation-conversation {
      .annotation-reference-name {
        margin: 0 16px;
      }
      .annotation-comment-text {
        font-family: "Nunito", sans-serif;
        font-style: normal;
        font-weight: 100;
        font-size: 10px;
        line-height: 130%;
        letter-spacing: normal;
        text-transform: capitalize;
        color: #666665;
        margin-left: 48px;
      }
      .annotation-comment-reply {
        font-family: "Nunito", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 130%;
        letter-spacing: normal;
        text-transform: capitalize;
        color: #999A9A;
        margin-left: 55px;
        margin-top: 14px;
        cursor: pointer;
      }
    }

    .annotation-list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      cursor: pointer;
      padding: 0 0 0 16px;
      width: 100%;
      height: 48px;
      border: 1px solid $login-bg-color;
      border-left: none;
      border-top: none;

      &:hover {
        background-color: #f9f9f9;
      }
    }

    .comment-wrapper{
      &:hover {
        background-color: #f9f9f9;
        border-radius: 5px;
      }
    }
  }
}