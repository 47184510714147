@import '../../styles/utils';
@import '../../styles/variables';
@import '../../styles/mixins';

.tile {
  width: 100%;
  margin-bottom: 1.5rem;

  @media (max-width: $desktop-breakpoint) {
    margin-right: 1.5rem;
    &:last-child {
      margin-right: 0;
    }
  }

  & .product-upload__drag-area {
    height: inherit;
    margin: 0;
  }

  &__player {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
  }

  &__player-height {
    height: 455px !important;
  }

  &__playIcon {
    @include center(both);
    top: 30%;
    cursor: pointer;
  }

  &__caption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $black-2;
    @include style-body-regular;
    font-size: 0.75rem;
    font-family: $nunito-bold;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: 1.25rem 0;
    position: relative;
  }

  &__expand {
    margin-right: 48px;
    position: absolute;
    right: 0;
  }
}

.model-button-load {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 100;
}
