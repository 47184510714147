@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.history-modal {
  .activity-list {
    .control-block {
      margin: 0;
    }
    &__item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include style-body-xsmall;
      color: #000;
    }
    &__item-avatar {
      border-radius: 100%;
      margin-right: 0.8rem;
      font-family: $nunito-bold;
      font-size: 0.8rem;
      background-color: $gray-light-2;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      object-fit: cover;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      position: relative;
    }
    &__item-activity-type {
      color: #999a9a;
    }
    &__item-activity-time {
      color: #999a9a;
      font-size: 10px;
      padding-top: 3px;
    }
  }
}
