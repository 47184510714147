@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.add-new-material {
  background-color: $neutral-white;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  max-width: 62.6rem;

  .as-file-preview__thumbnail {
    min-height: unset;
  }

  @media (max-width: $tablet-breakpoint) {
    max-width: 100%;
  }

  &__heading {
    @include style-title-medium;
    font-family: $optima;
    letter-spacing: 0;
    border-bottom: 1px solid $gray-light-3;
    padding: 1.5rem 2rem;
  }

  &__back {
    display: flex;
    @include style-title-small;
    text-transform: uppercase;
    color: $gray;
    cursor: pointer;
  }

  &__card-header {
    @include style-title-small;
    position: relative;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    padding: 1rem 0;
  }

  &__card {
    width: 50%;
    min-height: 250px;
    margin-right: 1rem;
    overflow: hidden;

    @media (max-width: $tablet-breakpoint) {
      width: 100%;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__card-wrap {
    border: 1px solid $gray-light-3;
    padding: 0.5rem 0.8rem;
    margin-bottom: 2.5rem;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__card-header {
    @include style-title-small;
    position: relative;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    padding: 1rem 0;
    margin-top: 0.6rem;
  }

  &__no-img {
    background: $drag-bg;
    border: 1px dashed $border-color;
    width: 100%;
    height: 200px;
    position: relative;

    @media (max-width: $tablet-breakpoint) {
      width: 100%;
    }

    > label,
    > button {
      @include center(both);
    }
    input[type='file'] {
      display: none;
    }

    .button-dashboard {
      &::after {
        content: url('../../../assets/icons/caret-bottom.svg');
        position: relative;
        left: 0.3rem;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
  }

  &__row {
    display: flex;
    width: 100%;
    @media (max-width: $tablet-breakpoint) {
      flex-direction: column;
    }
  }

  &__cards {
    display: flex;
    width: 55%;

    @media (max-width: $tablet-breakpoint) {
      width: 100%;
    }
  }

  &__fields {
    width: 45%;
    padding-left: 2.5rem;
    margin-top: 1rem;
    @media (max-width: $tablet-breakpoint) {
      padding-left: 0;
      width: 100%;
    }
  }

  &__img {
    background: $drag-bg;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 200px;

    @media (max-width: $tablet-breakpoint) {
      width: 100%;
    }
  }
}
