@import '../../../../styles/utils';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.as-file-preview {
  width: 100%;
  cursor: pointer;

  &__thumbnail {
    width: 100%;
    height: 100%;
    min-height: 21rem;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 70px;
      height: 77.78x;
    }
  }

  &__description {
    position: relative;
    padding: 0.75rem;
    background-color: $white;
    @include style-body-small;
    color: $gray-medium;
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__dots {
    opacity: 0;
    transition: 300ms ease all;
    margin-left: auto;
  }

  &:hover {
    .as-file-preview__dots {
      opacity: 1;
    }
  }

  @media (max-width: $tablet-breakpoint) {
    max-width: 100%;
  }

  // Lightbox
  &__lightbox {
    width: 100%;
    position: fixed;
    height: 100%;
    background: $white;
    padding: 2rem;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 99;
  }

  &__lightbox-img {
    width: 50rem;
    height: auto;
    position: relative;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    margin: 0rem auto;

    @media (max-width: $tablet-breakpoint) {
      width: 100%;
      margin-top: 2rem;
    }
  }

  &__lightbox-header {
    img {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
  }

  &__lightbox-img {
    @media (min-width: $tablet-breakpoint) {
      width: 100%;
      height: 100%;
    }
  }

  &__lightbox-inner {
    overflow-y: scroll;
  }

  .Checkboxes {
    position: absolute;
    margin-left: 0.75rem;
    opacity: 0;
  }

  &:hover {
    .Checkboxes {
      opacity: 1;
    }
  }

  &-active {
    .Checkboxes {
      opacity: 1;
    }
  }
}
