@import '../../../../styles/utils';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.model-viewer-wrapper {
  & .tile-player {
    height: 50%;
  }

  & .style-sample-review-header__wrap {
    & .style-sample-review-header__right-aligned {
      font-size: 0.75rem;
      align-items: center;

      & .color-gray-bg {
        width: auto;
        height: auto;
        border-radius: 50%;
      }
      span {
        margin-left: 5px;;
      }
    }
  }
}

.hotspot {
  background: rgb(255, 255, 255);
  border-radius: 32px;
  border: 0px;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px;
  box-sizing: border-box;
  cursor: pointer;
  height: 24px;
  padding: 8px;
  position: relative;
  transition: opacity 0.3s ease 0s;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hotspot > * {
  opacity: 1;
  transform: translateY(-50%);
}

.hotspot-annotation {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px;
  color: rgba(0, 0, 0, 0.8);
  display: block;
  font-family: Futura, 'Helvetica Neue', sans-serif;
  font-size: 18px;
  font-weight: 700;
  left: calc(100% + 1em);
  max-width: 128px;
  overflow-wrap: break-word;
  padding: 0.5em 1em;
  position: absolute;
  top: 50%;
  width: max-content;
}
