@import '../../styles/utils';
@import '../../styles/variables';
@import '../../styles/mixins';

.listing-page {
  &__info {
    @media (max-width: $desktop-breakpoint) {
      padding-top: 5rem;
    }
  }
  .modal {
    &__header {
      border-bottom: 1px solid $gray-light-3;
    }
  }
}
