@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.notifications {
  position: absolute;
  box-shadow: 0px 4px 8px rgba(39, 40, 51, 0.12);
  border-radius: 4px;
  top: 1rem;
  right: 0;
  background-color: $neutral-white;
  z-index: 999;
  width: 320px;
  cursor: pointer;

  &__header {
    border-bottom: 1px solid $gray-light-3;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__headline {
    @include style-heading-small;
    text-transform: uppercase;
  }

  &__read {
    @include style-heading-small;
    text-transform: uppercase;
    color: $gray;
    &:focus {
      outline: none;
    }
  }

  &__activity-type {
    color: #999a9a;
  }

  &__time {
    font-size: 10px;
    color: $gray;
  }

  &__content {
    width: 100%;
  }

  &__item {
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid $gray-light-3;
    @include style-body-xsmall;
    color: #000;
    position: relative;
    align-items: center;

    .notifications-popup__img {
      margin-right: 0;
    }

    .notifications-popup__content {
      padding-left: 0.8rem;
    }

    .frame__bell-wrap {
      position: absolute;
      top: 15px;
      right: 0px;
    }
    .frame__bell-dot-active {
      border: none;
    }
  }

  &__img,
  &__thumbnail {
    background-color: $gray-light-2;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 0.8rem;
    font-family: $nunito-bold;
    font-size: 0.8rem;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    position: relative;
  }
}
