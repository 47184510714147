@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.asset-annotation-modal {
  .atelier-model-3d-annotation-wrapper {
    .style-sample-review-header {
      width: 50%;
      margin: 5px auto;
      padding: 0 0;
    }
  }
  .atelier-model-3d-annotation-sbs-wrapper {
    .style-sample-review-header {
      margin: 5px auto;
      padding: 0 0;
    }
  }
  .react-transform-wrapper {
    margin: 0 auto;
    background-color: $login-bg-color;
  }

  .markup-summary-modal-wrapper {
    .modal {
      z-index: 1000;
    }
  }
}